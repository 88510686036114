import axios from 'axios';

const statesApi = 'https://www.universal-tutorial.com/api/';
const headers = {
  Accept: 'application/json',
  'api-token':
    'dvKES-__it3LLMuR2LKqwzCNiV9I3bQdDd5DfLXM-z0FSJzzGjwlFGE7oZHmYSf67h4',
  'user-email': 'robin@nyfulcrum.com',
};
export const getFreeStatesAuth = (...rest) =>
  axios.get(`${statesApi}getaccesstoken`, { headers }, ...rest);

export const getStates = (...rest) =>
  axios.get(`${statesApi}states/United States`, ...rest);

export default { getFreeStatesAuth, getStates };
