<template>
  <div class="row">
    <!-- thumbnail -->
    <div class="col-4 pr-0">
      <AppMediaViewer
        :src="thumbnail"
        width="100%"
        aspect-ratio="1/1"
        class="bg-white"
      />
    </div>
    <!-- descriptions -->
    <div class="col-8">
      <div class="">
        <h5 class="text-body-2">{{ productName }}</h5>
        <div class="row text-small">
          <div class="col-4">Size</div>
          <div class="col-8 font-weight-5 w-100 overflow-hidden">
            {{ variantName }}
          </div>
        </div>
        <div class="row mt-2 text-small">
          <div class="col-4">Qty</div>
          <div
            v-if="quantityOption && quantityOption.quantity"
            class="col-8 font-weight-5"
          >
            <span>{{ `${quantity} ${quantityOption.quantity.name}` }}</span>
            <span class="d-block text-caption">({{ `${getPcs} pcs` }})</span>
          </div>
          <div v-else class="col-8 font-weight-5">{{ quantity }} pcs</div>
        </div>
        <AppBtn text @click="$emit('remove')">Remove</AppBtn>
      </div>
    </div>
  </div>
</template>

<script>
import AppBtn from '../../../shared/elements/AppBtn.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';

export default {
  name: 'CartCheckoutItem',

  components: { AppBtn, AppMediaViewer },

  props: {
    thumbnail: { type: String, default: '' },
    productName: { type: String, default: '' },
    variantName: { type: String, default: '' },
    quantity: { type: [String, Number], default: 0 },
    quantityOption: { type: [Object, Number], default: () => ({}) },
  },

  computed: {
    getPcs() {
      if (!this.quantityOption.conversion) {
        return parseInt(this.quantity);
      }

      return parseInt(this.quantity) * this.quantityOption.conversion;
    },
  },
};
</script>

<style lang="scss" scoped></style>
