<template>
  <div class="h-100">
    <Topbar class="shadow-60">
      <template v-slot:title>{{
        $store.getters['cart/checkout_project'] || 'Checkout'
      }}</template>
    </Topbar>
    <AppLoading v-if="editLoading"></AppLoading>
    <div v-else class="h-100 row">
      <!-- Cart -->
      <div class="col-12 col-lg-2">
        <div class="p-3 h-100 bg-gray-light-4">
          <h4 class="d-none d-lg-block font-weight-normal">RFQ Items</h4>
          <!-- Mobile View show button -->
          <AppBtn
            text
            color="info"
            prependIcon="shopping_cart"
            class="w-100 d-lg-none"
            :appendIcon="showCart ? 'caret_up' : 'caret_down'"
            @click="showCart = !showCart"
          >
            Show order items
          </AppBtn>
          <!-- Cart Items -->
          <div class="cart-items pt-4" :class="[showCart ? 'show' : 'hide']">
            <!-- Cart Item -->
            <CartCheckoutItem
              class="mb-4 w-100"
              v-for="(item, index) in items"
              :key="`checkout-item-${index}`"
              :thumbnail="item.thumbnail"
              :productName="item.product_name"
              :variantName="item.variant_name"
              :quantity="item.quantity"
              :quantityOption="item.quantity_option"
              @remove="removeItem(item)"
            ></CartCheckoutItem>
          </div>
        </div>
      </div>
      <!-- Checkout Form -->
      <div class="col-12 col-lg-10">
        <div class="mt-lg-5 ml-auto mr-auto p-2" style="max-width: 900px">
          <GoBackBtn :url="{ name: 'ProductList' }" :alert="true">Back</GoBackBtn>
          <h4 class="text-center">QUOTE REQUEST DETAILS</h4>
          <!-- Form Step Indicator -->
          <AppSteps
            :activeStep="activeStep"
            :statusList="[
              'Order Details',
              'Contact Details',
              'Vendor Option',
              'Order Confirmation',
            ]"
            class="mt-5 mb-3"
          ></AppSteps>
          <!-- Forms -->
          <transition name="checkout-form" mode="out-in">
            <!-- Step 1 -->
            <div v-if="activeStep === 1" key="form-step-1">
              <h4 class="mb-4">ORDER DETAIL</h4>
              <!-- Project Name -->
              <AppInput
                v-if="id"
                v-model="chosenProjects[0]"
                label="Project Name"
                class="mb-2 w-100"
                :autosuggestItem="projects"
                :validations="[validation.required()]"
              ></AppInput>
              <AppInputItems
                v-else-if="true"
                v-model="chosenProjects"
                multiple
                delimiter=","
                placeholder="Separate projects by comma (,)"
                label="Project Name"
                class="mb-2 w-100"
                :items="projects"
                :hideError="chosenProjects.length > 0"
                :validations="[validation.required()]"
              ></AppInputItems>

              <!-- PO Number -->
              <div class="mb-5 row">
                <div
                  v-for="(poNum, index) in poNumbers"
                  :key="`po-${index}`"
                  class="col-6"
                >
                  <label class="w-100 text-truncate">
                    <span>PO Number for</span>
                    <span class="ml-1 font-weight-5">
                      {{ chosenProjects[index] }}
                    </span>
                  </label>
                  <AppInput
                    v-model="poNumbers[index]"
                    class="mb-2"
                    labelClass="text-truncate"
                  ></AppInput>
                </div>
              </div>

              <!-- Delivery Options -->
              <div class="mt-4">
                <label class="w-100 m-0 font-weight-bold text-uppercase">
                  DELIVERY OPTIONS
                  <span class="text-danger">*</span>
                </label>
                <AppInputRadio
                  v-model="form.delivery_type"
                  :label="'pick up' | deliveryTypeLabel"
                  radioValue="pick up"
                ></AppInputRadio>
                <AppInputRadio
                  v-model="form.delivery_type"
                  :label="'ship to address' | deliveryTypeLabel"
                  radioValue="ship to address"
                  class="ml-5"
                ></AppInputRadio>
              </div>

              <!-- Has ForkLift -->
              <AppInputCheckbox
                v-model="form.has_forklift"
                label="Piggy back forklift or any other pertinent information."
              ></AppInputCheckbox>

              <!-- Has ForkLift -->
              <div class="d-flex align-items-center">
                <AppInputCheckbox
                  v-model="form.is_union_delivery"
                  label="Union Delivery"
                  hideError
                ></AppInputCheckbox>
                <!-- <AppTooltip
                  class="ml-2"
                  tooltipText="Lorem Ipsum Dolor"
                ></AppTooltip> -->
              </div>

              <div class="mt-4 row">
                <!-- Deliver Date -->
                <div class="col-12 col-md-6">
                  <AppInputDateTime
                    v-model="form.delivery_date"
                    noTime
                    minToday
                    label="deliver by"
                    class="w-100"
                    :validations="[validation.required()]"
                  ></AppInputDateTime>
                </div>
                <div class="col-12 col-md-6">
                  <AppInputDateTime
                    minToday
                    v-model="form.bidding_end_date"
                    label="End bidding date"
                    class="w-100"
                    :validations="[
                      validation.requiredIf($route.params.cart === undefined),
                    ]"
                  ></AppInputDateTime>
                </div>
                <!-- Bidding Date -->
              </div>

              <div>
                <div class="mt-4">
                  <label class="mb-3 w-100 font-weight-bold text-uppercase">
                    receive daily reminder
                  </label>
                  <AppInputItems
                    v-model="form.email_reminder"
                    label="Email addresses"
                    delimiter=","
                    placeholder="Separate email address by comma (,)"
                    class="w-100"
                  ></AppInputItems>
                  <!-- Error Message -->
                  <span v-if="emailError" class="app-input-error">
                    {{ emailError }}
                  </span>
                  <!-- <AppInputCheckbox
                    v-model="form.sms_reminder"
                    label="SMS"
                    class="d-inline-block ml-5"
                  ></AppInputCheckbox> -->
                </div>
              </div>
            </div>
            <!-- Step 2 -->
            <div v-else-if="activeStep === 2" key="form-step-2">
              <h4 class="mb-4">CONTACT DETAILS</h4>
              <AppInput
                v-model="form.full_name"
                label="full name"
                class="w-100"
                :validations="[validation.required()]"
              ></AppInput>

              <div class="row mt-3">
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.email"
                    label="Email"
                    class="w-100"
                    :validations="[validation.required()]"
                  ></AppInput>
                </div>
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.phone"
                    label="Phone"
                    class="w-100"
                    :validations="[validation.required()]"
                  ></AppInput>
                </div>
              </div>

              <div class="mt-5">
                <h4 class="mb-4">DELIVERY DETAILS</h4>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <AppInput
                      v-model="form.address"
                      label="Address"
                      class="w-100"
                      :validations="[validation.required()]"
                    ></AppInput>
                  </div>
                  <div class="col-12 col-md-6">
                    <AppInput
                      v-model="form.city"
                      label="City"
                      class="w-100"
                      :validations="[validation.required()]"
                    ></AppInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <AppInputSelect
                      v-model="form.state"
                      searchable
                      label="State"
                      class="w-100"
                      :items="states"
                      :shouldSelectFirstItem="false"
                      :validations="[validation.required()]"
                    ></AppInputSelect>
                  </div>
                  <div class="col-12 col-md-6">
                    <AppInput
                      v-model="form.zip_code"
                      label="Zip Code"
                      class="w-100"
                      :validations="[validation.required()]"
                    ></AppInput>
                  </div>

                  <div class="col-12">
                    <AppInputTextArea
                      v-model="form.delivery_note"
                      label="Notes"
                      class="w-100"
                      placeholder="Description of delivery address e.g. landmark"
                    ></AppInputTextArea>
                  </div>
                </div>
              </div>
            </div>
            <!-- Step 3 -->
            <div v-else-if="activeStep === 3" key="form-step-2">
              <h4 class="mb-4">VENDOR OPTIONS</h4>
              <AppInputRadio
                v-model="vendorOption"
                radioValue="all"
                label="Send to all vendor"
              ></AppInputRadio>
              <div class="d-flex flex-column">
                <AppInputRadio
                  v-model="vendorOption"
                  radioValue="specific"
                  label="Send to specific vendors"
                  class="mr-3"
                ></AppInputRadio>
                <AppInputSelect
                  v-if="Object.keys(companies).length > 0"
                  v-model="form.requests"
                  :shouldSelectFirstItem="false"
                  multiple
                  searchable
                  @searched="(e) => (searchedCompanies.data = e)"
                  :disabled="vendorOption !== 'specific'"
                  :items="
                    companies.map((company) => ({
                      value: company.id,
                      text: company.name,
                    }))
                  "
                >
                </AppInputSelect>
              </div>
            </div>
            <!-- Step 4 -->
            <div v-else key="form-step-3">
              <h4 class="mb-4">ORDER DETAIL</h4>
              <!-- Order Detail Card -->
              <div class="mb-4 p-md-3 p-2 shadow-40">
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Project Name</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ chosenProjects.join(', ') }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">PO Number</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ poNumbers.filter((item) => item).join(', ') }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Delivery Option</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    <span class="m-0">
                      {{ form.delivery_type | deliveryTypeLabel }}
                    </span>
                    <span v-if="form.has_forklift" class="m-0">
                      | Piggy back forklift or any other pertinent information.
                    </span>
                    <span v-if="form.is_union_delivery" class="m-0">
                      | Union Delivery.
                    </span>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Delivery Schedule</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{
                      form.delivery_date ||
                      '2021-08-03T17:42' | readableDate(false)
                    }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Email Reminder Interval</div>
                  <div class="col-7 col-md-8 font-weight-5">Daily</div>
                </div>
              </div>

              <h4 class="mb-4">CONTACT</h4>
              <!-- Contact Card -->
              <div class="p-2 shadow-40">
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Full Name</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ form.full_name }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Email</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ form.email }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Phone</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ form.phone }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Delivery Address</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{
                      `${form.address}, ${form.city}, ${form.state}, ${form.zip_code}`
                    }}
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-5 col-md-4">Notes</div>
                  <div class="col-7 col-md-8 font-weight-5">
                    {{ form.delivery_note || 'N/A' }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <!-- Action Btn -->
          <div class="mt-4 d-flex flex-column flex-lg-row justify-content-end">
            <AppBtn
              v-if="activeStep > 1"
              outline
              class="mb-3 mb-lg-0"
              :disabled="loading"
              @click="() => (activeStep -= 1)"
            >
              BACK
            </AppBtn>
            <AppBtn
              v-if="activeStep < 4"
              class="ml-lg-3"
              :disabled="!stepsComplete"
              @click="() => (activeStep += 1)"
            >
              NEXT
            </AppBtn>
            <AppBtn
              v-if="activeStep > 3"
              class="ml-lg-3"
              :loading="loading"
              :disabled="items.length === 0"
              @click="confirmOrder = true"
            >
              CONFIRM ORDER REQUEST
            </AppBtn>
          </div>
          <div class="mt-4 d-flex justify-content-end">
            <p v-if="innerError" class="text-danger text-small">
              {{ innerError }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Confirm Order Modal -->
    <ActionModal v-model="confirmOrder" @confirm="sendOrder">
      <template v-slot:title>
        {{ $route.params.id ? 'Edit Order' : 'Confirm Order' }}
      </template>
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>
    <!-- Success Order Modal -->
    <SuccessModal v-model="successOrder" @confirm="goToPendingOrders">
      <template v-slot:title> Order Created </template>
      <template v-slot:description>
        Click "Proceed" to view pending RFQ
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>
    <SuccessModal v-model="successOrderApproved" @confirm="goToPendingOrders">
      <template v-slot:title> Order Created </template>
      <template v-slot:description>
        Your order is placed. You can start receiving quotation now.
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>
  </div>
</template>

<script>
import Topbar from '@/components/Partial/Layouts/LayoutBuyer/Topbar.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppInput from '@/shared/elements/AppInput.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import AppInputDateTime from '@/shared/elements/AppInputDateTime.vue';
import AppInputRadio from '@/shared/elements/AppInputRadio.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import fileExtensionChecker from '@/shared/utils/fileExtensionChecker';
import CartCheckoutItem from '@/components/Buyer/CartCheckout/CartCheckoutItem.vue';
import OrderConstants from '@/shared/constants/OrderConstants';
import _readableDateTime from '@/shared/mixins/_readableDateTime.js';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
// import AppTooltip from '@/shared/elements/AppTooltip.vue';
import validation from '@/shared/utils/validation';
import AppInputItems from '@/shared/elements/AppInputItems.vue';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import AppInputTextArea from '@/shared/elements/AppInputTextArea.vue';
import { DateTime } from 'luxon';
import AppSteps from '@/shared/elements/AppSteps.vue';
import States from '@/shared/api/States';
import Company from '@/shared/api/Company';
import Orders from '@/shared/api/Orders';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import AppLoading from '../../../../shared/elements/AppLoading.vue';
import CartItem from '../../../../shared/api/CartItems';
import Cart from '../../../../shared/api/Cart';

export default {
  components: {
    Topbar,
    AppBtn,
    AppInput,
    AppInputCheckbox,
    AppInputRadio,
    AppInputDateTime,
    AppInputSelect,
    CartCheckoutItem,
    // AppTooltip,
    AppInputItems,
    GoBackBtn,
    ActionModal,
    AppInputTextArea,
    AppSteps,
    SuccessModal,
    AppLoading,
  },
  name: 'ProductCheckout',

  mixins: [_readableDateTime, _appApiHelper],

  data() {
    const user = this.$store.getters['auth/user'];
    const id = this.$route.params.id;

    return {
      id,
      validation,
      OrderConstants,
      activeStep: 1,
      emailError: null,
      innerError: false,
      poNumbers:
        !id && !this.$store.getters['cart/checkout_project'] ? [] : [''],
      chosenProjects:
        !id &&
        (!this.$store.getters['cart/checkout_project'] ||
          !this.$store.getters['cart/checkout_project'].replace(/\s/g, '')
            .length)
          ? []
          : [id ? '' : this.$store.getters['cart/checkout_project']],
      form: {
        // step 1
        project_name: this.$store.getters['cart/checkout_project'],
        po_number: null,
        delivery_type: null,
        has_forklift: false,
        is_union_delivery: false,
        delivery_date: null,
        bidding_end_date: null,
        requests: null,
        email_reminder: [],
        // step 2
        full_name: `${user?.information?.first_name} ${user?.information?.last_name}`,
        email: user.email,
        phone: user?.mobile_number,
        address: user?.information?.company_address || user?.information?.address,
        city: user?.information?.company_city || user?.information?.city,
        state: user?.information?.company_state || user?.information?.state,
        zip_code: user?.information?.company_zip_code || user?.information?.zip_code,
        delivery_note: '',
      },
      editItem: [],
      companies: [],
      vendorOption: 'all',
      // for mobile view
      confirmOrder: false,
      successOrder: false,
      successOrderApproved: false,
      showCart: false,
      editLoading: false,
      searchedCompanies: [],
    };
  },

  computed: {
    projects() {
      return this.$store.getters['cart/cart']
        .filter((item) => item.project_name.replace(/\s/g, '').length > 0)
        .filter(
          (item, index, self) =>
            index ===
            self.findIndex((t) => t.project_name === item.project_name)
        )
        .map((item) => item.project_name);
    },
    items() {
      return this.$route.params.id
        ? this.editItem
        : this.$store.getters['cart/checkout'];
    },
    stepsComplete() {
      const form = this.form;

      switch (this.activeStep) {
        case 1:
          return (
            this.chosenProjects.length > 0 &&
            form.delivery_type &&
            form.delivery_date &&
            (form.bidding_end_date || this.$route.params.cart === true) &&
            (form.email_reminder.length > 0 ? !this.emailError : true)
          );
        case 2:
          return (
            form.full_name &&
            form.email &&
            form.phone &&
            form.address &&
            form.city &&
            this.states.includes(form.state) &&
            form.zip_code
          );
        case 3:
          return this.vendorOption === 'specific'
            ? form.requests?.length
            : true;
        case 4:
          return true;
        default:
          return false;
      }
    },
  },

  watch: {
    items(val) {
      if (val.length === 0) {
        this.innerError = 'Please select an item first';
      } else {
        this.innerError = '';
      }
    },
    chosenProjects: {
      handler(val) {
        // different PO Numbers to match
        const oldPoNumbers = this.poNumbers;
        this.poNumbers = [];
        for (let i = 0; i < val.length; i++) {
          this.poNumbers.push(oldPoNumbers[i] || '');
        }

        // assign new project name
        const cart_project_id = this.$store.getters['cart/checkout_project_id'];
        const cart_project = this.$store.getters['cart/checkout_project'];
        if (cart_project_id && val[0]) {
          if (this.$store.getters['cart/cart']) {
            if (cart_project !== val[0]) {
              Cart.updateCart(cart_project_id, { project: val[0] });
              this.$store.getters['cart/checkout'].map((item, index) => {
                item.project_name = val[0];
                this.$store.getters['cart/checkout'][index] = item;
              });
            }
          }
          this.$store.dispatch('cart/renameProject', {
            project_id: cart_project_id,
            new_name: val[0],
          });

          this.$store.commit('cart/SET_CHECKOUT_PROJECT', {
            project_name: val[0],
            project_id: cart_project_id,
          });
        }
      },
      deep: true,
    },
    'form.email_reminder': {
      handler(val) {
        const emails = val.filter((email) => !/\S+@\S+\.\S+/.test(email));

        this.emailError =
          emails.length > 0
            ? emails.length
              ? `${emails[0]} is not a valid email address.`
              : null
            : val.length > 2
            ? 'Maximum of 2 email addresses only.'
            : null;
      },
    },
  },

  filters: {
    deliveryTypeLabel(value) {
      const { DELIVERY_TYPE } = OrderConstants;
      const deliveryTypeOBj = DELIVERY_TYPE.find(
        (type) => type.value === value
      );
      return deliveryTypeOBj.text;
    },
  },

  methods: {
    ...fileExtensionChecker,
    async getCompanies() {
      const params = {
        vendor_only: 1,
        all: 1,
      };

      const response = await Company.getCompanies({ params });

      const { data } = response;

      this.companies = data;

      this.searchedCompanies = Object.assign({}, this.companies);
    },
    async fetchOrder() {
      this.editLoading = true;
      const apiResponse = await Orders.getOrder(this.id);
      const order = apiResponse.data;
      // order detrails
      this.chosenProjects = [order.project_name];
      this.poNumbers = [order.po_number];
      // this.form.po_number = order.po_number;
      this.form.delivery_type = order.delivery_type;
      this.form.has_forklift = order.has_forklift === 1;
      this.form.is_union_delivery = order.is_union_delivery === 1;
      this.form.delivery_date = order.delivery_date.slice(0, 10);
      // bidding date transform date
      const biddingEndRaw = DateTime.fromFormat(
        order.bidding_end_date,
        'yyyy-MM-dd HH:mm:ss'
      );
      const biddingEnd = `${biddingEndRaw.toISODate()}T${biddingEndRaw.toFormat(
        'HH:mm'
      )}`;
      this.form.bidding_end_date = biddingEnd;
      this.form.email_reminder = order.email_reminder;
      // contact details
      this.form.full_name = order.full_name;
      this.form.email = order.email;
      this.form.phone = order.phone;
      this.form.address = order.address;
      this.form.city = order.city;
      this.form.state = order.state;
      this.form.zip_code = order.zip_code;
      this.form.delivery_note = order.delivery_note;

      // approval preference
      this.form.preference = order?.preference;

      // items
      this.editItem = order.products;
      this.editLoading = false;
    },
    removeItem(item) {
      if (this.$route.params.id) {
        const productIndex = this.editItem.findIndex(
          (product) =>
            product.product_id === item.product_id &&
            product.product_variant_id === item.product_variant_id &&
            product.product_variant_id === item.product_variant_id &&
            product.quantity_id === item.quantity_id
        );

        if (productIndex >= 0) {
          this.editItem.splice(productIndex, 1);
        }
      } else {
        if (this.$store.getters['cart/checkout_project']) {
          this.$store.dispatch('cart/removeToCart', {
            project_name: this.$store.getters['cart/checkout_project'],
            item: item,
          });
        }
        CartItem.deleteCartItem(item.id).then(() => {
          this.$store.dispatch('cart/removeToCart', {
            project_name: ' ',
            item: item,
          });
          this.$store.dispatch('cart/removeToCheckout', item);
        });
      }
    },
    async sendOrder() {
      await this.chosenProjects.forEach(async (project, index) => {
        const form = {
          ...this.form,
          project_name: project,
          po_number: this.poNumbers[index],
          has_forklift: this.form.has_forklift ? 1 : 0,
          is_union_delivery: this.form.is_union_delivery ? 1 : 0,
          products: this.items,
        };

        if (!form.requests) {
          delete form.requests;
        }
        try {
          if (index + 1 === this.chosenProjects.length) {
            this.$store.dispatch('api/setModal', {
              header: 'Order Created',
              description: 'Click "Proceed" to view pending RFQ',
              btnText: 'Proceed',
              onClick: () => {
                if (!this.error) {
                  if (this.id) {
                    this.$router.push({
                      name: 'BuyerOrderView',
                      params: { id: this.id },
                    });
                  } else {
                    this.$router.push({ name: 'BuyerOrderRequest' });
                  }
                }
              },
            });
          }
          let response = null;
          if (this.$route.params.id) {
            response = await Orders.editOrder(this.$route.params.id, form);
          } else {
            response = await Orders.postOrder(form);
          }

          if (response.status === 200) {
            this.$store.dispatch('cart/clearProjectCart', project);
            this.$store.dispatch('cart/clearCheckout');
            this.id = response.data.id;

            if (response.data.status !== 'approved') {
              this.successOrder = true;
            } else {
              this.successOrderApproved = true;
            }
          }
        } catch {
          this.innerError = 'There was something wrong please try again';
        }
      });
    },
    async getStates() {
      const getAuth = await States.getFreeStatesAuth();
      if (!getAuth.hasError) {
        const headers = {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuth.data.auth_token}`,
        };
        const getStates = await States.getStates({ headers });
        this.states = getStates.data.map((state) => state.state_name);
      }
    },
    goToPendingOrders() {
      if (!this.error) {
        if (this.id) {
          this.$router.push({
            name: 'OrderView',
            params: { 
              id: this.id,
              origin: 'OrderCheckout'
            },
          });
        } else {
          this.$router.push({ name: 'OrderRequestList' });
        }
      }
    },
  },
  mounted() {
    this.getCompanies();
    this.getStates();
    if (this.id) {
      this.fetchOrder();
    }

    if (this.items.length === 0) {
      this.innerError = 'Please select an item first';
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.checkout-form {
  &-enter-active,
  &-leave-active {
    transition: transform 0.2s ease-in-out, opacity 0.1s ease-in-out;
  }

  &-enter {
    transform: translate(-20%);
    opacity: 0;
  }
  &-leave-to {
    opacity: 0;
    transform: translate(50%);
  }
}

.cart-items {
  @include media-lg('below') {
    overflow: hidden;
    transition: max-height 0.3s;

    &.hide {
      max-height: 0;
    }
    &.show {
      max-height: 100%;
    }
  }
}
</style>
