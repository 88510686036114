<template>
  <div class="app-input-checkbox d-inline-block">
    <div
      class="app-input-checkbox-container d-inline-flex align-items-center"
      @click="toggleCheckbox"
      @keypress="toggleCheckbox"
    >
      <AppIcon
        hoverable
        class="app-input-checkbox-checkbox p-2 d-inline-block rounded-circle"
        tabindex="0"
        :name="innerVal === radioValue ? activeIcon : inactiveIcon"
        :class="innerVal === radioValue ? `text-primary` : 'text-black'"
      ></AppIcon>
      <div
        :for="name"
        class="
          app-input=checkbox-label
          m-0
          d-inline
          text-body-2
          font-weight-normal
        "
      >
        <span class="d-inline-block">{{ label }}</span>
        <!-- Required Asterisk -->
        <span v-if="isRequired" class="text-danger">*</span>
      </div>
      <!-- <input ref="radioInput" type="radio" v-model="innerVal"> -->
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="w-100 d-block text-caption text-danger">
      {{ error }}
    </span>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppInput',

  components: { AppIcon },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    radioValue: {
      type: [Boolean, String, Number, Array, Object],
      default: false,
    },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    inactiveIcon: { type: String, default: 'radiobutton' },
    activeIcon: { type: String, default: 'radiobutton_checked' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
  },

  data() {
    return {
      focus: false,
    };
  },

  methods: {
    toggleCheckbox() {
      this.innerVal = this.radioValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';

.app-input-checkbox {
  &-container {
    cursor: pointer;
  }
  &-checkbox {
    transition: background-color 0.2s;
    &:hover {
      @extend .bg-white-dark-1;
    }
  }

  span {
    min-height: 18px;
  }
}
</style>
