<template>
  <!-- Customized Topbar for Vendor Layout -->
  <Topbar>
    <!-- Select a type of product to add (not yet done) -->
    <CartDrawer v-model="showCart"></CartDrawer>
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <template v-slot:prepend-icons>
      <AppIcon
        hoverable
        name="shopping_cart"
        class="m-2 ml-3 mr-md-5"
        @click="showCart = true"
      ></AppIcon>
    </template>
  </Topbar>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import Topbar from '../../Topbar/Topbar.vue';
import CartDrawer from '../../Cart/CartDrawer.vue';

export default {
  name: 'LayoutVendorTopbar',

  components: { Topbar, AppIcon, CartDrawer },

  data() {
    return {
      showCart: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
